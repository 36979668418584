<template lang="pug">
  .account-list
    #tooltip-container
    TopBar(
      :shops="shops"
      :shops-loading="shopsLoading"
      :roles="roles"
      :roles-loading="rolesLoading"
      :filters="filters"
      @change-filters="applyFilters"
    )
    .accounts-table-wrapper
      AppOverlayLoader(:state="accountsLoading")
      .accounts-table
        VueGoodTable.table-wrapper(
          :rows="accountsList"
          :columns="columns"
          :sort-options="{ initialSortBy: sorting }"
          @on-sort-change="handleSorting"
        )
          template(v-slot:table-column="props")
            span(:title="props.column.label") {{ $t(props.column.label) }}
          template(v-slot:table-row="{ column, row, formattedRow }")
            .shops(v-if="column.field === 'shops'")
              AppListExpandable(
                :key="`shops-${row.id}`"
                :items="row.shops"
                valueKey="id"
                titleKey="name"
              )
            .username(v-else-if="column.field === 'full_name'")
              AppTextExpandable(
                :key="`username-${row.id}`"
                :text="formattedRow.full_name"
              )
            .email(v-else-if="column.field === 'email'")
              AppTextExpandable(
                :key="`email-${row.id}`"
                :text="formattedRow.email"
              )
            .role(v-else-if="column.field === 'role'")
              #tooltip-container
              .warning(v-if="isEmpty(row.role)")
                AppTooltip(
                  icon="exclamation-triangle"
                  type="danger"
                  :title="noUserRoleTip"
                  container="#tooltip-container"
                )
              span(v-else) {{ row.role.name }}
            .table-actions(v-else-if="column.field === 'actions'")
              AppIconButton.action-button(
                :class="{ disabled: !row.editable }"
                icon="edit"
                @click="editUser(row)"
              )
              AppIconButton.action-button(
                :class="{ disabled: !row.editable || row.id === currentUser.id }"
                icon="trash-alt"
                @click="deleteUser(row)"
              )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count",
        :per-page="pagination.per_page"
        :auto-scroll-on-pagination="{ target: '.table-wrapper' }"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // store modules
  import shopMatchingModule from "@/config/store/matching/shop"
  import rolesModule from "@/config/store/roles"
  import accountsModule from "@/config/store/accounts"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withPermissions from "@/mixins/withPermissions"
  import withConfirmation from "@/mixins/withConfirmation"

  // components
  import { VueGoodTable } from "vue-good-table"

  // misc
  import { columns } from "./AccountsTableConfig"
  import { mapState } from "vuex"
  import { map, isEmpty, isEqual } from "lodash-es"
  import { SERIALIZER_VIEW_SHORT } from "@/config/constants"
  import "vue-good-table/dist/vue-good-table.css"

  const shopMatchingsMixin = withStoreModule(shopMatchingModule, {
    name: "shopMatching",
    readers: { shops: "items", shopsLoading: "loading" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const rolesMixin = withStoreModule(rolesModule, {
    name: "roles",
    readers: { roles: "items", rolesLoading: "loading" },
    actions: { fetchRoles: "FETCH_ITEMS" }
  })

  const accountsMixin = withStoreModule(accountsModule, {
    name: "accounts",
    readers: {
      accounts: "items",
      accountsLoading: "loading",
      filters: "filters",
      pagination: "pagination",
      sorting: "sorting"
    },
    actions: { fetchAccountsAction: "FETCH_ITEMS", deleteAccount: "DELETE_ITEM" },
    mutations: {
      setFilters: "SET_FILTERS",
      setPagination: "SET_PAGINATION_DATA",
      setSorting: "SET_SORTING"
    }
  })

  export default {
    components: {
      VueGoodTable,
      TopBar: () => import("./TopBar"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppTextExpandable: () => import("@/components/elements/AppTextExpandable"),
      AppListExpandable: () => import("@/components/elements/AppListExpandable"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [shopMatchingsMixin, rolesMixin, accountsMixin, withPermissions, withConfirmation],

    mounted() {
      this.fetchShops({ pagination: { _disabled: true } })
      this.fetchRoles({ forceReload: false })
      this.fetchAccounts()
    },

    computed: {
      ...mapState(["currentUser"]),

      columns,

      accountsList() {
        return this.accounts || []
      },

      noUserRoleTip() {
        return this.$t("account_management.account_list.no_user_role_tip")
      }
    },

    methods: {
      map,
      isEmpty,

      fetchAccounts() {
        this.fetchAccountsAction({ serializer_view: SERIALIZER_VIEW_SHORT })
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchAccounts()
      },

      applyFilters(filters) {
        this.setFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.fetchAccounts()
      },

      editUser(user) {
        if (this.currentUser.id === user.id) {
          return this.$router.push({ name: "EditOwnAccount" })
        }

        if (user.editable) {
          this.$router.push({ name: "EditAccount", params: { id: user.id } })
        }
      },

      deleteUser(user) {
        if (user.editable && this.currentUser.id !== user.id) {
          this.$confirm({
            title: this.$t("accounts.delete_confirmation", { name: user.account_id }),
            resolve: {
              handler: () => this.deleteAccount(user.id)
            }
          })
        }
      },

      handleSorting([sortingData]) {
        if (isEqual(sortingData, this.sorting)) return

        this.setSorting(sortingData)
        this.fetchAccounts()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/tables/vue-good-table.sass"

  .app-overlay-loader
    z-index: 1

  #tooltip-container
    +tooltip($default-red)

  .accounts-table-wrapper
    position: relative

    ::v-deep
      .accounts-table
        +listing-container(-10px)
        +app-vue-table

        .vgt-table
          border: none
          width: fit-content
          min-width: 100%

          .account-id-field
            width: fit-content

          .last-login-at-field
            width: fit-content

          .expandable-field
            max-width: 220px

          .vgt-right-align
            // overwrite text align for date fields
            text-align: left

          th
            background: $th-background-color
            border-bottom: 1px solid $default-purple-light-line
            border-left: 1px solid $border-element-color
            border-right: 1px solid $border-element-color
            border-top: none
            color: $default-purple
            font-size: 0.8rem
            font-weight: 700
            padding: 17px 22px 17px 7px
            white-space: nowrap
            width: fit-content

            &:first-child
              border-left: 1px solid $default-purple-light-line

            &:last-child
              border-right: 1px solid $default-purple-light-line

          td
            border-bottom: 1px solid $default-purple-light-line
            border-left: 1px solid $border-element-color
            border-right: 1px solid $border-element-color
            border-top: 1px solid $default-purple-light-line
            color: $td-color
            font-size: 0.8rem
            padding: 11px 7px
            width: fit-content

            &:first-child
              border-left: 1px solid $default-purple-light-line

            &:last-child
              border-right: 1px solid $default-purple-light-line

            &.actions-field
              width: 1%
              min-width: 70px
              white-space: nowrap

            .role
              white-space: nowrap
              .warning
                text-align: center

                .app-tooltip
                  margin: 0

            .table-actions
              text-align: center

              .action-button
                +icon-button($default-purple)
</style>
